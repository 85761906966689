@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  background: linear-gradient(92.23deg,
      #ffbb01 21.43%,
      #e29000 50.63%);
  box-shadow: 0px 4.42184px 107.23px rgba(254, 253, 254, 0.51);
}

.btn:hover {
  background: linear-gradient(92.23deg,
      #ffbb01 1%,
      #bb6502 99%);
}

.gradient,
.active {
  background: linear-gradient(92.23deg,
      #e29000 1%,
      #984e08 99%);
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

@layer base {
  body {
    @apply font-sans text-lg leading-8 text-white;
  }

  .h2 {
    @apply font-sans text-[32px] mb-6 tracking-[10%] uppercase;
  }

  .h3 {
    @apply font-sans font-semibold text-[26px] mb-6 leading-[46px];
  }

  .btn {
    @apply rounded-full font-sans text-white font-medium;
  }

  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }

  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }

  .btn-link {
    @apply text-base;
  }

  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#42A6E3] to-[#FF56F6] font-bold font-sans hover:from-[#FF56F6] hover:to-[#42A6E3];
  }

  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}